import React from 'react'
import PropTypes from 'prop-types'

const PageTitle = ({ pageTitle }) => {
  return <h3 className='title has-text-centered has-text-light is-3'>{pageTitle}</h3>
}

PageTitle.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

PageTitle.defaultProps = {
  pageTitle: '',
}

export default PageTitle
